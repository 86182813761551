body {
  background-color: #ddd;
}

#login {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;

  .login-form {
    max-width: 380px;
    width: 100%;
    margin: auto;
    background-color: #fff;
    padding: 24px  48px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .logo {
    margin-bottom: 24px;
    display: flex;
    img {
      width: 160px;
      height: 160px;
      margin: auto;
    }
  }

  .actions {
    .ant-btn {
      font-size: 18px;
      width: 100%;
      &:nth-child(n + 1) {
        margin-top: 24px;
      }
    }
  }
}

#login .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}
